// welcome section start
.cmn-section-title {
    text-align: center;
    margin-bottom: 55px;
    display: flex;
    align-items: center;
    width: 100%;
    h2 {
        flex-shrink: 0;
    }
    span {
        width: 100%;
        height: 2px;
        background-color: #c59a6d;
        margin-inline: 40px;
    }
    @include breakpoint(max-lg) {
        margin-bottom: 35px;
        span {
            margin-inline: 20px;
        }
    }
}
.welcome-section {
    padding-top: 70px;
    padding-bottom: 140px;
    @include breakpoint(max-lg) {
        padding-bottom: 60px;
    }
    .arrow-right,
    .arrow-left {
        position: absolute;
        bottom: 0;
        right: 0px;
        width: 55px;
        height: 55px;
        padding: 18px;
        background-color: #d3b5a3;
        z-index: 1;
        path {
            fill: #fff;
        }
    }
    .arrow-left {
        right: 56px;
    }
}
.welcome-wrapper {
    width: 91%;
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
    align-items: end;
    gap: 50px;
    @include breakpoint(max-lg) {
        width: 100%;
        flex-wrap: wrap;
    }
}
.welcome-thumb {
    width: 64%;
    height: 580px;
    @include breakpoint(max-lg) {
        width: 100%;
        height: 300px;
    }
    img {
        width: 100%;
        height: 100%;
        height: 580px;
        object-fit: cover;
        @include breakpoint(max-lg) {
            height: 300px;
        }
    }
}
.welcome-content {
    width: 31%;
    @include breakpoint(max-lg) {
        width: 100%;
        padding-inline: 20px;
    }
    h2 {
        margin-bottom: 35px;
        @include breakpoint(max-lg) {
            margin-bottom: 20px;
        }
    }
    p {
        @include breakpoint(max-lg) {
            font-size: 16px;
        }
    }
    .btn-wrapper {
        margin-top: 35px;
        margin-bottom: 28px;
        @include breakpoint(max-lg) {
            margin-top: 0px;
            padding-top: 20px;
            margin-bottom: 0;
        }
        a {
            font-size: 20px;
            position: relative;
            @include breakpoint(max-lg) {
                font-size: 18px;
            }
            &::before {
                position: absolute;
                content: "";
                left: 0;
                bottom: -7px;
                right: 0;
                width: 100%;
                height: 3px;
                background-color: hsl(var(--base));
                transition: all ease 0.3s;
            }
            &:hover {
                &::before {
                    transform: scaleX(0);
                }
            }
        }
    }
}
// welcome section end

// client logo slide start
.client-section {
    padding-bottom: 140px;
    padding-inline: 15px;
    @include breakpoint(max-lg) {
        padding-bottom: 80px;
    }
}
.client-slider {
    .client-single-item {
        padding-inline: 15px;
        width: 235px;
        height: 257px;
        @media (max-width: 1500px) {
            height: 180px;
        }
        @include breakpoint(max-lg) {
            padding-inline: 5px;
            height: 120px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
// client logo slide end

// about section start
.about-section {
    padding-bottom: 140px;
    @include breakpoint(max-lg) {
        padding-bottom: 80px;
    }
}
.about-wrapper {
    width: 91%;
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 50px;
    margin-left: auto;
    @include breakpoint(max-lg) {
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
}
.about-thumb {
    width: 64%;
    height: 580px;
    @include breakpoint(max-lg) {
        width: 100%;
        height: 300px;
    }
    img {
        width: 100%;
        height: 580px;
        object-fit: cover;
        @include breakpoint(max-lg) {
            height: 300px;
        }
    }
}
.about-content {
    width: 31%;
    @include breakpoint(max-lg) {
        width: 100%;
        padding-inline: 20px;
    }
    h2 {
        margin-bottom: 35px;
        @include breakpoint(max-lg) {
            margin-bottom: 20px;
        }
    }
    p {
        @include breakpoint(max-lg) {
            font-size: 16px;
        }
    }
    .btn-wrapper {
        margin-top: 35px;
        margin-bottom: 28px;
        @include breakpoint(max-lg) {
            margin-top: 0px;
            padding-top: 20px;
            margin-bottom: 0;
        }
    }
    a {
        font-size: 20px;
        transition: all ease 0.3s;
        position: relative;
        @include breakpoint(max-lg) {
            font-size: 18px;
        }
        &::before {
            position: absolute;
            content: "";
            left: 0;
            bottom: -7px;
            right: 0;
            width: 100%;
            height: 3px;
            background-color: hsl(var(--base));
            transition: all ease 0.3s;
        }
        &:hover {
            &::before {
                transform: scaleX(0);
            }
        }
    }
}
// about section end

// shop section start
.shop-section {
    background-color: #f4f4f3;
    padding-top: 135px;
    padding-bottom: 65px;
    @include breakpoint(max-lg) {
        padding-top: 80px;
    }
    .arrow-right,
    .arrow-left {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        width: 55px;
        height: 55px;
        padding: 18px;
        background-color: #d3b5a3;
        z-index: 1;
        @include breakpoint(max-lg) {
            width: 40px;
            height: 40px;
            top: 35%;
            transform: translateY(-50%);
            padding: 13px;
        }
        path {
            fill: #fff;
        }
    }
    .arrow-right {
        left: auto;
        right: 0px;
    }
    .slick-list.draggable {
        padding: 0 !important;
    }
}
.product-wrapper {
    padding-inline: 30px;
    @include breakpoint(max-lg) {
        padding-inline: 10px;
    }
    .product-item {
        padding-inline: 10px;
        &__content {
            background-color: #fff;
            padding: 22px 25px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            @include breakpoint(max-lg) {
                padding: 15px 15px;
            }
            .left-content {
                width: 240px;
                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    @include breakpoint(max-lg) {
                        font-size: 15px;
                    }
                }
                h3 {
                    font-size: 28px;
                    margin-bottom: 14px;
                    @include breakpoint(max-lg) {
                        font-size: 22px;
                    }
                }
            }
            .middle-border {
                width: 3px;
                height: 110px;
                margin-right: 35px;
                margin-left: 30px;
                background-color: hsl(var(--base));
                @include breakpoint(max-xxl) {
                    margin-left: 15px;
                    margin-right: 15px;
                }
                @include breakpoint(max-lg) {
                    margin-left: 7px;
                    margin-right: 10px;
                    height: 80px;
                }
            }
            .right-price {
                width: 70px;
                h2 {
                    font-size: 32px;
                    @include breakpoint(max-lg) {
                        font-size: 24px;
                    }
                }
            }
        }
        img {
            width: 100%;
            height: 520px;
            object-fit: cover;
            @include breakpoint(max-lg) {
                height: 250px;
            }
        }
    }
}
.all-product-btn {
    text-align: center;
    padding-top: 95px;
    @include breakpoint(max-lg) {
        padding-top: 50px;
    }
    a {
        padding: 28px 51px;
        font-size: 22px;
        text-align: center;
        background-color: #fff;
        border: 1px solid #707070;
        @include breakpoint(max-lg) {
            padding: 17px 45px;
            font-size: 18px;
        }
        &:hover {
            border-color: #c59a6d;
            background-color: #c59a6d;
            color: #fff;
        }
    }
}
// shop section end

// project gallery section start
.project-gallery {
    padding-top: 90px;
    overflow: hidden;
    @include breakpoint(max-lg) {
        padding-top: 80px;
    }
}
.gallery-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
    @include breakpoint(max-lg) {
        margin: -2.5px;
    }
    .gallery-item {
        width: calc(100% / 8);
        padding: 5px;
        @include breakpoint(max-lg) {
            padding: 2.5px;
            width: calc(100% / 3);
        }
        .gallery-inner {
            overflow: hidden;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
            transition: all ease 0.3s;
            overflow: hidden;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}
.loader {
    position: absolute;
    bottom: 75px;
    left: 50%;
    transform: translateX(-50%);
    @include breakpoint(max-lg) {
        bottom: 60px;
    }
}
.all-product-btn {
    &.style-two {
        padding-top: 70px;
    }
    button {
        padding: 22px 51px;
        font-size: 22px;
        text-align: center;
        background-color: #fff;
        border: 1px solid #707070;
        @include breakpoint(max-lg) {
            padding: 17px 45px;
            font-size: 18px;
        }
        &:hover {
            border-color: #c59a6d;
            background-color: #c59a6d;
            color: #fff;
        }
    }
}
// project gallery section end

// contact now part start
.contact-now {
    padding-top: 122px;
    padding-bottom: 115px;
    @include breakpoint(max-lg) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    text-align: center;
    h2 {
        margin-bottom: 10px;
        font-size: 40px;
        @include breakpoint(max-lg) {
            font-size: 28px;
        }
    }
    img {
        @include breakpoint(max-lg) {
            width: 35px;
        }
    }
}
// contact now part end

// modal

.modal {
    .arrow-right,
    .arrow-left {
        position: absolute;
        bottom: 0;
        right: 0px;
        width: 55px;
        height: 55px;
        padding: 18px;
        background-color: #d3b5a3;
        z-index: 1;
        path {
            fill: #fff;
        }
    }
    .arrow-left {
        right: 56px;
    }
}
.modal-dialog.modal-dialog-centered {
    max-width: 80%;
    border-radius: 10px;
    overflow: hidden;
    margin-left: auto !important;
    margin-right: auto !important;

    @media (max-width: 1500px) {
        max-width: 85%;
    }
    @media (max-width: 991px) {
        .welcome-wrapper {
            gap: 30px;
        }
    }
    img {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    .modal-content {
        border-radius: 10px;
        border: none;
        @include breakpoint(max-lg) {
            width: 100%;
            margin-inline: auto;
        }
    }
    .btn-close {
        position: absolute;
        right: 20px;
        top: -50px;
        width: 50px;
        font-size: 26px;
        color: #fff;
        filter: invert(300%);
        opacity: 1;
        @include breakpoint(max-lg) {
            font-size: 20px;
            right: 0px;
            top: -35px;
        }
        &:focus {
            box-shadow: none;
        }
    }
    @include breakpoint(max-lg) {
        .welcome-content {
            padding-bottom: 40px;
        }
        img {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: 10px;
        }
    }
}
