// hero section start
.hero-section {
    position: relative;
    height: 810px;
    @include breakpoint(max-lg) {
        height: 350px;
    }
    .container {
        max-width: 100%;
        padding: 0;
    }
    .arrow-right,
    .arrow-left {
        position: absolute;
        bottom: 0;
        right: 40px;
        width: 55px;
        height: 55px;
        padding: 18px;
        background-color: #d3b5a3;
        z-index: 1;
        @include breakpoint(max-lg) {
            display: none !important;
        }
        path {
            fill: #fff;
        }
    }
    .arrow-left {
        right: 96px;
    }
    .slick-dots {
        position: absolute;
        bottom: 17px;
        li {
            background-color: #d3b5a3;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            transition: all ease 0.3s;
            margin-inline: 7.5px;
            button {
                opacity: 0;
            }
            &.slick-active {
                background-color: #ffffff;
            }
        }
    }
}
.single-slide {
    position: relative;
    height: 810px;
    @include breakpoint(max-lg) {
        height: 350px;
    }
    &::before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        height: 350px;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.74) 100%
        );
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .hero-content {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 110px;
        width: 500px;
        z-index: 2;
        max-width: 500px;
        text-align: center;
        margin-inline: auto;
        @include breakpoint(max-lg) {
            bottom: 70px;
        }
        h1 {
            color: #fff;
            font-weight: 300;
        }
    }
}
// hero section end
