html {
    scroll-behavior: smooth;
    font-size: 100%;
}
body {
    padding: 0;
    margin: 0;
    font-size: 18px;
    line-height: 1.45;
    color: hsl(var(--body));
    font-family: var(--body-font);
    font-weight: 400;
}
a {
    text-decoration: none;
    color: hsl(var(--body));
    &:hover {
        color: hsl(var(--base));
    }
}
a,
img,
.name {
    transition: all ease 0.3s;
}
span,
sub,
sup,
a {
    display: inline-block;
}

.bg_img {
    background-position: center;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}
.bg_fixed {
    background-attachment: fixed !important;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.scrollToTop {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: hsl(var(--white));
    z-index: 9999;
    top: 40px;
    right: 30px;
    border-radius: 50%;
    font-size: 14px;
    position: absolute;
    border: 1px solid #fff;
    transform: translateY(150px);
    @include breakpoint(max-lg) {
        display: none;
    }
}

.scrollToTop:hover {
    color: hsl(var(--white));
}

.scrollToTop.active {
    transform: translateY(0);
    animation: bounceInDown 2s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 400;
    line-height: 1.3;
    color: hsl(var(--heading));
    font-family: var(--heading-font);
}
h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a {
    color: hsl(var(--heading));
}
h1 {
    font-size: 50px;
    @include breakpoint(max-lg) {
        font-size: 28px;
    }
}
h2 {
    font-size: 35px;
    @include breakpoint(max-lg) {
        font-size: 24px;
    }
}

h3 {
    font-size: 28px;
    @include breakpoint(max-lg) {
        font-size: 20px;
    }
}

.overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    min-height: 100%;
    background: transparent;
    opacity: 0;
    transition: all ease 0.3s;
    z-index: -1;
}

.overlay.active {
    background: rgba(31, 31, 31, 0.4);
    opacity: 1;
    z-index: 99;
}
