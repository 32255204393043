header {
    padding-block: 23px;
    background-color: #fff;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    @include breakpoint(max-lg) {
        padding-block: 10px;
    }
    .container {
        max-width: 100%;
        padding-inline: 40px;
        @include breakpoint(max-lg) {
            padding-inline: 20px;
        }
    }
}

.logo {
    max-width: 205px;
    max-height: 79px;
    @include breakpoint(max-lg) {
        max-width: 120px;
        img {
            width: 100%;
        }
    }
}
.menu {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 50px;
    margin-top: 18px;
    @include breakpoint(max-xl) {
        gap: 10px 20px;
    }
    li {
        a {
            border-bottom: 3px solid transparent;
            padding-bottom: 10px;
            font-size: 18px;
        }
        &.active {
            a {
                border-color: #d3b5a3;
                @include breakpoint(max-lg) {
                    font-weight: 500;
                }
            }
        }
    }
}

.header-content-right {
    display: flex;
    align-items: center;
    @include breakpoint(max-lg) {
        gap: 15px;
    }
    .contact-info {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 40px;
        list-style: none;
        @include breakpoint(max-xl) {
            gap: 20px;
        }
        @include breakpoint(max-lg) {
            gap: 10px;
            li {
                &:first-child {
                    display: none;
                }
            }
        }
        a {
            font-size: 20px;
            @include breakpoint(max-lg) {
                font-size: 16px;
                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .left-toggler {
        cursor: pointer;
        width: 25px;
        -webkit-transition: all ease 0.3s;
        transition: all ease 0.3s;
    }
    .left-toggler li {
        background-color: #5d5e60;
        width: 25px;
        height: 1px;
    }

    .left-toggler li:not(:last-child) {
        margin-bottom: 3px;
    }
    .left-toggler li {
        width: 25px;
        height: 1px;
        background-color: #003319;
        line-height: 1;
        padding: 0;
        margin: 0;
        margin-left: auto;
        -webkit-transition: all ease 0.3s;
        transition: all ease 0.3s;
    }

    .left-toggler li:not(:last-child) {
        margin-bottom: 4px;
    }
    .left-toggler.active {
        transform: translateX(5px);
    }
    .left-toggler.active li:first-child {
        transform: rotate(-45deg) translate(-8px, 2px);
    }

    .left-toggler.active li:last-child {
        transform: rotate(45deg) translate(-4.5px, 1px);
    }

    .left-toggler.active li:nth-child(2) {
        opacity: 0;
    }
}

.menu-wrapper {
    @include breakpoint(max-lg) {
        position: fixed;
        top: 66px;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100vh;
        background-color: #d3b5a3;
        padding-inline: 40px;
        padding-top: 50px;
        transform: translateX(-100%);
        transition: all ease 0.3s;
        ul {
            gap: 0;
        }
        li {
            width: 100%;
            text-align: center;
            line-height: 1;
            &:not(:last-child) {
                border-bottom: 1px solid #fff;
            }
            a {
                border: none;
                padding: 15px 0;
            }
        }
        &.active {
            transform: translateX(0);
        }
    }
}
