@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

:root {
    // Fonts
    --heading-font: "Rubik", sans-serif;
    --body-font: "Rubik", sans-serif;

    //Colors
    --heading: 60 6% 25%;
    --body: 60 6% 25%;
    --border: 0 0% 44%;

    // Base Color Shade
    --base-h: 23;
    --base-s: 35%;
    --base-l: 73%;
    --base: var(--base-h) var(--base-s) var(--base-l);

    --base-100: var(--base-h) var(--base-s) calc(var(--base-l) + 20%);
    --base-200: var(--base-h) var(--base-s) calc(var(--base-l) + 15%);
    --base-300: var(--base-h) var(--base-s) calc(var(--base-l) + 10%);
    --base-400: var(--base-h) var(--base-s) calc(var(--base-l) + 5%);
    --base-600: var(--base-h) var(--base-s) calc(var(--base-l) - 8%);
    --base-700: var(--base-h) var(--base-s) calc(var(--base-l) - 10%);
    --base-800: var(--base-h) var(--base-s) calc(var(--base-l) - 15%);
    --base-900: var(--base-h) var(--base-s) calc(var(--base-l) - 20%);
}
