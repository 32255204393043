.form-label {
    font-size: 15px;
}
.form--control {
    height: 50px;
    border-radius: 5px;
    border: 1px solid hsl(var(--border));
    outline: 0 !important;
    box-shadow: none !important;
    &:focus {
        border: 1px solid hsl(var(--base));
    }
}
.form--control[readonly] {
    border-color: hsl(var(--border));
    background-color: hsl(var(--light));
}
textarea.form--control {
    height: 130px;
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-text-fill-color: hsl(var(--heading));
    transition: background-color 5000s ease-in-out 0s;
}

.custom--radio {
    position: relative;
    padding-left: 0;
    input[type="radio"] {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        cursor: pointer;
        &:checked {
            ~ label {
                &::before {
                    border-width: 1px;
                    border-color: hsl(var(--base)) !important;
                }
                &::after {
                    opacity: 1;
                }
            }
        }
    }
    label {
        margin-bottom: 0;
        position: relative;
        padding-left: 20px;
        font-size: 14px;
        font-weight: 400;
        &::before {
            position: absolute;
            content: "";
            top: 4px;
            left: 0;
            width: 15px;
            height: 15px;
            border: 1px solid #999;
            border-radius: 50%;
        }
        &::after {
            position: absolute;
            content: "";
            top: 8px;
            left: 4px;
            width: 7px;
            height: 7px;
            background-color: hsl(var(--base)) !important;
            opacity: 0;
            border-radius: 50%;
        }
    }
}
/* form css end*/
