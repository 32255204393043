// footer section start
footer {
    background-color: #dddfdb;
    padding-top: 56px;
    padding-bottom: 60px;
    @include breakpoint(max-lg) {
        padding-top: 60px;
        padding-bottom: 40px;
    }
    .container {
        max-width: 1600px;
        @include breakpoint(max-lg) {
            padding-inline: 20px;
        }
    }
}
.footer-wrapper {
    padding-bottom: 78px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include breakpoint(max-lg) {
        align-items: center;
        gap: 35px;
        padding-bottom: 50px;
    }
}
.footer-left {
    .logo {
        margin-bottom: 50px;
        @include breakpoint(max-lg) {
            margin-bottom: 30px;
            max-width: 150px;
        }
    }
    p {
        font-size: 22px;
        margin-bottom: 0px;
        @include breakpoint(max-lg) {
            font-size: 18px;
        }
    }
}
.footer-right {
    max-width: 405px;
    span {
        font-size: 22px;
        margin-bottom: 15px;
        @include breakpoint(max-lg) {
            font-size: 20px;
        }
    }
    .form-group {
        margin-bottom: 15px;
        input {
            height: 40px;
            padding-inline: 15px;
            border-radius: 0px;
            border: 1px solid #fff;
            outline: 0 !important;
            box-shadow: none !important;
            font-size: 14px;
            background-color: #fff;
            width: 100%;
            &:focus {
                border-color: hsl(var(--base));
            }
        }
    }
    .checkbox-wrapper {
        padding-top: 25px;
        display: flex;
        align-items: flex-start;
        font-size: 12px;
        gap: 16px;
        input {
            border-radius: 0px !important;
            border-color: #707070;
            // transform: translateY(6px);
        }
    }
}
.btn-wrapper {
    padding-top: 30px;
    button {
        background-color: #d3b5a3;
        font-size: 14px;
        border: 1px solid hsl(var(--base));
        padding: 12px 37px;
        letter-spacing: 1px;
        transition: all ease 0.3s;
        &:hover {
            color: hsl(var(--base));
            background-color: #fff;
        }
    }
}
.footer-bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    @include breakpoint(max-lg) {
        gap: 10px;
    }
    .footer-bottom-left {
        display: flex;
        flex-wrap: wrap;
        li {
            padding-left: 10px;
            &:first-child {
                @include breakpoint(max-lg) {
                    padding-left: 0px;
                }
            }
            &:last-child {
                a {
                    margin-inline: 10px;
                }
            }
        }
    }
    .footer-bottom-right {
        p {
            margin-bottom: 0px;
        }
    }
}
// footer section end
